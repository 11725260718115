.webWrapper {
  background: url("https://res.cloudinary.com/dtmnmiu76/image/upload/v1733424624/bg_wkfv0m.jpg")
    repeat-y center top fixed;
  background-size: cover;
  background-position-y: 0px;
}

.wiggling-background {
  animation: wiggle 5s infinite;
}

@keyframes wiggle {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(0);
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}
