html,
body,
#root {
  font-family: "Ledger", serif;
  /* font-weight: 400; */
  /* font-optical-sizing: auto; */
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.container-fluid {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.d-flex {
  display: flex;
  flex-grow: 1;
}

.flex-grow-1 {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.p-3 {
  padding: 1rem;
}

a {
  color: black;
}

a {
  pointer-events: all;
  color: black;
  text-decoration: none;
}

svg {
  fill: black;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

canvas {
  opacity: 0;
  touch-action: none;
  animation: fade-in 5s ease 1s forwards;
}
